/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
// import websocketInit from '../websocketInit';
import Loading from './Loading';
import {
  USER_UPDATE,
} from '../actions';
import api from '../services/api';
import { isPathnameAllowed } from '../containers/navigation';

const ProtectedRoute = ({ children, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.userStore.isAuthenticated);
  const token = useSelector((state) => state.tokenStore.token);
  const sites = useSelector((state) => state.userStore.sites);
  const history = useHistory();
  const routerLocation = useLocation();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(true);
  const [pathnameAllowed, setPathnameAllowed] = useState(false);

  useEffect(() => {
    if (isPathnameAllowed(routerLocation.pathname, sites)) {
      setPathnameAllowed(true);
    } else {
      setPathnameAllowed(false);
    }
  }, [routerLocation.pathname]);

  useEffect(() => {
    if (!token) {
      const from = { pathname: '/logout' };
      history.replace(from);
      return;
    }

    api.get('/api/auth/user').then((response) => {
      dispatch({ type: USER_UPDATE, ...response.data });
      // websocketInit();
    }).catch(() => {
      //
    }).finally(() => {
      setRefresh(false);
    });
  }, []);

  return refresh ? <Loading /> : (
    <Route
      {...rest}
      render={({ location }) => (isAuthenticated && pathnameAllowed ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
