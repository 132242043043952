/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
const defaultPage = 'site.workspaces';

const navigationMap = new Map([
  ['site.users', {
    name: 'Users', to: '/users', icon: 'cil-puzzle', _tag: 'CSidebarNavItem',
  }],
  ['site.organizations', {
    name: 'Organizations', to: '/organizations', icon: 'cil-bell', _tag: 'CSidebarNavItem',
  }],
  [defaultPage, {
    name: 'Workspaces', to: '/workspaces', icon: 'cil-pencil', _tag: 'CSidebarNavItem',
  }],
  ['site.coupons', {
    name: 'Coupon', to: '/coupons', icon: 'cil-cursor', _tag: 'CSidebarNavItem',
  }],
  ['site.campaigns', {
    name: 'Campaigns', to: '/campaigns', icon: 'cil-chart-line', _tag: 'CSidebarNavItem',
  }],
  ['site.settings', {
    name: 'Settings', to: '/settings', icon: 'cil-star', _tag: 'CSidebarNavItem',
  }],
  ['site.themes', {
    name: 'Themes', to: '/themes', icon: 'cil-drop', _tag: 'CSidebarNavItem',
  }],
  ['site.importer', {
    name: 'Importer', to: '/importer', icon: 'cil-bell', _tag: 'CSidebarNavItem',
  }],
  ['site.linkqr', {
    name: 'Link & Qrcode', to: '/linkqr', icon: 'cil-pencil', _tag: 'CSidebarNavItem',
  }],
  ['site.demo', {
    name: 'Demo Pages', to: '/demo', icon: 'cil-pencil', _tag: 'CSidebarNavItem',
  }],
  ['site.deviceorder', {
    name: 'Device Order', to: '/device-order', icon: 'cil-star', _tag: 'CSidebarNavItem',
  }],
  ['site.roles-permissions', {
    name: 'Roles & Permissions', to: '/roles-permissions', icon: 'cil-filter', _tag: 'CSidebarNavItem',
  }],
  // ['ApiMls', {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'ApiMls',
  //   route: '/mlsmapping',
  //   to: '/mlsmapping',
  //   icon: 'cil-drop',
  //   _children: [
  //     { name: 'Mls Mapping', to: '/mlsmapping', _tag: 'CSidebarNavItem' },
  //   ],
  // }],
  // ['DataFixer', {
  //   name: 'Data Fixer', to: '/datafixer', icon: 'cil-puzzle', _tag: 'CSidebarNavItem',
  // }],
  // ['DataBrowser', {
  //   name: 'Data Browser', to: '/databrowser', icon: 'cil-bell', _tag: 'CSidebarNavItem',
  // }],
  // ['Notifications', {
  //   name: 'Notifications', to: '/notificationsview', icon: 'cil-pencil', _tag: 'CSidebarNavItem',
  // }],
  // ['MlsWireTools', {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Mls Wire Tools',
  //   route: '/mls-wire',
  //   to: '/mls-wire/mlsconfiguration',
  //   icon: 'cil-cursor',
  //   _children: [
  //     { name: 'Configuration', to: '/mls-wire/mlsconfiguration', _tag: 'CSidebarNavItem' },
  //     { name: 'Metadata', to: '/mls-wire/mlsmetadata', _tag: 'CSidebarNavItem' },
  //     { name: 'Geo Job', to: '/mls-wire/geojob', _tag: 'CSidebarNavItem' },
  //     { name: 'Class Jobs', to: '/mls-wire/classjobs', _tag: 'CSidebarNavItem' },
  //     { name: 'Photo Job', to: '/mls-wire/photojob', _tag: 'CSidebarNavItem' },
  //   ],
  // }],
  // ['Monitor', {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Monitor',
  //   route: '/classjoberror',
  //   to: '/classjoberror',
  //   icon: 'cil-star',
  //   _children: [
  //     { name: 'Class Job Error', to: '/classjoberror', _tag: 'CSidebarNavItem' },
  //     { name: 'Photo Job Error', to: '/photojoberror', _tag: 'CSidebarNavItem' },
  //   ],
  // }],
]);

const defaultAllowdPages = [
  '',
  '/',
  '/login',
  '/logout',
];

const generateSidebar = (sites) => {
  const pages = [];

  for (const item of sites) {
    const page = navigationMap.get(item);
    if (page) {
      pages.push(page);
    }
  }
  return pages;
};

const getDefaultPage = (sites) => {
  const page = Array.isArray(sites) && sites.length > 0 ? sites[0] : null;

  const selectedPage = page || defaultPage;
  const navigation = navigationMap.get(selectedPage) || navigationMap.get(defaultPage);

  return navigation.to;
};

const pathnameMatchBegin = (pathname, page) => {
  const pattern = new RegExp(`^${page}/`);
  return pattern.test(pathname);
};

const isPathnameAllowed = (pathname, sites) => {
  if (defaultAllowdPages.indexOf(pathname) !== -1) {
    return true;
  }

  let pageName = null;
  for (const [key, item] of navigationMap) {
    if ('_children' in item) {
      for (const children of item._children) {
        if (pathname === children.to || pathnameMatchBegin(pathname, children.to)) {
          pageName = key;
          break;
        }
      }
    } else if (pathname === item.to || pathnameMatchBegin(pathname, item.to)) {
      pageName = key;
      break;
    }
  }

  return pageName && !!sites.find((item) => item === pageName);
};

export {
  generateSidebar,
  getDefaultPage,
  isPathnameAllowed,
};
