import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import './scss/style.scss';
import ProtectedRoute from './components/ProtectedRoute';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Logout = React.lazy(() => import('./views/pages/logout/Logout'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={() => <Login />} />
            <Route exact path="/logout" name="Logout Page" render={() => <Logout />} />
            <Route exact path="/404" name="Page 404" render={() => <Page404 />} />
            <Route exact path="/500" name="Page 500" render={() => <Page500 />} />
            <ProtectedRoute path="/" name="Home">
              <TheLayout />
            </ProtectedRoute>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
