import { combineReducers } from 'redux';
import {
  CHANGE_UI_STATE, TOKEN_UPDATE, TOKEN_REMOVE, USER_UPDATE, USER_REMOVE,
} from '../actions';

import {
  TOKEN,
} from '../services/api';

const USER_STORE = 'USER_STORE';

const changeUiState = (state = {
  sidebarShow: 'responsive',
}, { type, ...rest }) => {
  switch (type) {
    case CHANGE_UI_STATE:
      return { ...state, ...rest };
    default:
      return state;
  }
};

const tokenStore = (state = {
  token: localStorage.getItem(TOKEN) || null,
}, action) => {
  switch (action.type) {
    case TOKEN_UPDATE:
      localStorage.setItem(TOKEN, action.token);
      return { ...state, token: action.token };
    case TOKEN_REMOVE:
      localStorage.removeItem(TOKEN);
      return { token: null, refreshToken: null };
    default:
      return state;
  }
};

const defaultUserState = {
  id: null,
  name: null,
  email: null,
  role: null,
  level: null,
  isAuthenticated: false,
  organization_id: null,
  organization_name: null,
  organization_settings: null,
  organization_type: null,
  childRoles: [],
  impersonate: [],
  sites: [
    'site.workspaces',
  ],
  gui: [],
};

const userStore = (state = {
  ...defaultUserState,
  ...JSON.parse(localStorage.getItem(USER_STORE)),
}, { type, ...rest }) => {
  switch (type) {
    case USER_UPDATE:
      localStorage.setItem(USER_STORE,
        JSON.stringify({
          ...state,
          ...rest,
          isAuthenticated: true,
        }));
      return {
        ...state,
        ...rest,
        isAuthenticated: true,
      };
    case USER_REMOVE:
      localStorage.removeItem(USER_STORE);
      return {
        ...defaultUserState,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  changeUiState,
  tokenStore,
  userStore,
});

export default rootReducer;
